import * as React from 'react';
import * as styles from './ImageContainer.module.css';

import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { deleteImage } from '../../../../store/form';
import { openWindowBox } from '../../../../store/modal';

const ImageContainer = (props) => {
    const dispatch = useDispatch();
    const [imageClasses, setImageClasses] = useState([styles.imageContainer]);

    const handleDeleteImage = (event) => {
        const imageIdToDelete = event.target.parentElement.id;
        setImageClasses([styles.imageContainer, styles.shrink]);
        setTimeout(()=>{
            dispatch(deleteImage({ imageId: imageIdToDelete }));
        }, 300)
    }

    const handleOpenWindowBox = (src, name, size) => {
        dispatch(openWindowBox({src: src, name: name, size: size}));
    }

    useEffect(()=>{
        setTimeout(()=>{
            setImageClasses((prevClasses)=>{
                return [...prevClasses, styles.show]
            })
        }, props.batchNumber * 100)
           
    }, [props.batchNumber])
    return (
        <div className={imageClasses.join(" ")} id={props.imageId}>
            <div className={styles.deleteImage} id={'delete'} onClick={handleDeleteImage}>
                <div id='deleteIcon'></div>
                <div id='deleteIcon'></div>
            </div>
            <div className={styles.imageWrapper}>
                <img className={styles.thumbnailImage} src={props.data} alt={props.name} onClick={()=>{handleOpenWindowBox(props.data, props.name, props.UISize)}} />
            </div>
            <div className={styles.metadata}>
                <p className={styles.name}>{props.name} </p>
                <i><p className={styles.size}>({props.UISize})</p></i>
            </div>
        </div>
    )
}

export default ImageContainer;