import * as styles from './Checkboxes.module.css';
import * as React from 'react';
import Checkbox from './Checkbox/Checkbox';
import {memo} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import { tickCheckbox } from '../../../store/form';
const Checkboxes = () => {

    const checkboxes = useSelector(state=> state.form.fields.find(field=>field.name === 'tasks').checkboxes);
    const dispatch = useDispatch();

    const updateCheckbox = (event) => {
       
        dispatch(tickCheckbox({id: event.target.id}))
    }

    const checkboxElements = checkboxes.map((checkbox) => {
        return <Checkbox 
        name={checkbox.name} 
        key={checkbox.name}
        checked={checkbox.checked} 
        updateCheckbox={updateCheckbox} 
        label={checkbox.label}/>
    })

    return (
        <div className={styles.checkboxes}>
            <p>What will be involved in the job?  Check as many as apply.</p>
<div className={styles.checkboxGrid}>
            {checkboxElements}
        </div>
        </div>
        
    )
}

export default memo(Checkboxes);