// extracted by mini-css-extract-plugin
export var WindowBoxWrapper = "WindowBox-module--WindowBoxWrapper--e3ad3";
export var show = "WindowBox-module--show--cf1af";
export var background = "WindowBox-module--background--d5a8d";
export var container = "WindowBox-module--container--4c813";
export var details = "WindowBox-module--details--30706";
export var exit = "WindowBox-module--exit--56d98";
export var showDesktopExit = "WindowBox-module--showDesktopExit--46b59";
export var showMobileDetails = "WindowBox-module--showMobileDetails--f3cbd";
export var showDesktopDetails = "WindowBox-module--showDesktopDetails--173b2";
export var showMobileExit = "WindowBox-module--showMobileExit--c2e14";
export var mobileHeader = "WindowBox-module--mobileHeader--b8ba6";