import * as styles from './Checkbox.module.css';
import * as React from 'react';
import {memo} from 'react';

const Checkbox = (props)=>{
  
    return(
        <div key={props.name} className={styles.checkboxItem} >
        <div className={props.checked ? styles.checkboxChecked : styles.checkboxUnchecked} ></div>
         <input
            type="checkbox"
            key={Math.random()}
            id={props.name}
            value={props.name}
            checked={props.checked}
            onChange={props.updateCheckbox}
        />
         <label htmlFor={props.name}>{props.label}</label>
</div>
    )
}

export default memo(Checkbox);