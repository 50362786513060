import * as styles from './Modal.module.css';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../store/modal';

import { useEffect, useState, useRef, useCallback } from 'react';

const Modal = (props) => {

    let invalidFieldList = [];
    const modal = useSelector(state => state.modal);
    const [modalClasses, setModalClasses] = useState([styles.modalContainer]);
    const closeRef= useRef();

    const dispatch = useDispatch();

    if (modal.invalidFields.length > 0) {
        modal.invalidFields.forEach((field) => {
            invalidFieldList.push(<li key={`${field} error`} className={styles.invalidField}>{field}</li>)
        })
    }

    const handleClose = useCallback((event) => {
        if(event.type === 'click' || event.keyCode === 27 || event.keyCode === 13){
        setModalClasses([styles.modalContainer]);
        setTimeout(()=>{
            dispatch(closeModal());
        }, 200)
    }
    }, [dispatch])

    useEffect(()=>{
        if(typeof window !== undefined){
            window.addEventListener('keydown', handleClose)
            }
            closeRef.current.focus();
        setModalClasses((prevModalClasses)=>{
            return [...prevModalClasses, styles.show]
        })
        return ()=>{window.removeEventListener('keydown', handleClose)}
    }, [handleClose])

    return (
        <div className={modalClasses.join(" ")}>
            <div className={styles.modal}>
                <h2>{modal.title}</h2>
                <div className={styles.modalBodyText}>
                    <p>{modal.message}</p>
                    {modal.invalidFields.length > 0 ? <ul className={styles.invalidFields}> {invalidFieldList} </ul> : null}
                </div>
                <button tabIndex="0" ref={closeRef} onKeyPress={handleClose} onClick={handleClose}>Okay</button>
            </div>
            <div  tabIndex="0" role="button" onKeyPress={handleClose} className={styles.background} onClick={handleClose}>
            </div>
        </div>
    )
}

export default Modal;