import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyB0ClqHbngV9ziWkLG2aA1L_jTlinmNGkg",
    authDomain: "mk-renovation.firebaseapp.com",
    databaseURL: "https://mk-renovation-default-rtdb.firebaseio.com",
    projectId: "mk-renovation",
    storageBucket: "mk-renovation.appspot.com",
    messagingSenderId: "333943956752",
    appId: "1:333943956752:web:57bbde20453d1c784f7195"
  };

  let db = null;
  let storage = null;


  export const getFirebaseDatabase = ()=> {
    if (typeof window !== 'undefined') {
      if (db && storage) return {db: db, storage: storage};
      let app = initializeApp(firebaseConfig);
      db = getFirestore(app);
      storage = getStorage(app)
      return {db: db, storage: storage};
    }
    return {db: null, storage: null};
  }

