import { useEffect } from 'react';
import * as React from 'react';

import * as styles from '../page-styles/WorkRequest.module.css';

import Card from '../components/ui/Card/Card';
import Loading from '../components/ui/Loading/Loading';
import Modal from '../components/ui/Modal/Modal';
import WindowBox from '../components/ui/WindowBox/WindowBox';

import FormField from '../components/work-request/FormField/FormField';
// import craftEmail from '../components/work-request/Email';
import Checkboxes from '../components/work-request/Checkboxes/Checkboxes';
import ImageSection from '../components/work-request/ImageSection/ImageSection';

import Layout from '../components/Layout/Layout';
import Seo from '../components/seo';
import { doc, setDoc } from "firebase/firestore"; 
import { getFirebaseDatabase } from '../../firebase';
import {ref, uploadString} from 'firebase/storage'
import {v4 as uuid} from 'uuid';




//Redux

import { useDispatch, useSelector } from 'react-redux';
import { updateTextField, touchTextField, resetForm, setFormLoading } from '../store/form';
import { openModal } from '../store/modal';

const WorkRequest = (props) => {
    const dispatch = useDispatch();
    const {db, storage} = getFirebaseDatabase();
   
    const images = useSelector(state => state.form.fields.find(field => field.name === 'images').imageData);
    const fields = useSelector(state => state.form.fields);
    const tasks = useSelector(state => state.form.fields.find(field => field.name === 'tasks'));
    const formLoading = useSelector(state => state.form.formLoading);
    const imageFieldData = fields.find((field) => field.name === 'images');
    const totalImageSize = useSelector(state => state.form.totalImageSize);

    const modal = useSelector(state => state.modal);
    


    const handleUpdateTextField = (event) => {
        event.preventDefault();
        const id = event.target.id;
        const value = event.target.value;

        dispatch(updateTextField({ id: id, value: value }))
    }

    const handleMarkFieldAsTouched = (event) => {
        event.preventDefault();
        const id = event.target.id;

        dispatch(touchTextField({ id: id, touched: true }))
    }


    useEffect(()=>{
        if(typeof window !== 'undefined'){
        window.scrollTo(0,0)
        }  
}, [])
    const validateForm = () => {
        let invalidFields = [];
        for (let field in fields) {
            if (fields[field].required) {
                if (!fields[field].isValid) {
                    invalidFields.push(fields[field].label)
                }
            }
        }

        return invalidFields;
    }

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(setFormLoading({ loading: true }));
        const formData = {};

        const invalidFields = validateForm();
        if (invalidFields.length !== 0) {
            dispatch(setFormLoading({ loading: false }));
            for (let field in fields) {
                if (fields[field].required) {
                    dispatch(touchTextField({ id: fields[field].name, touched: true }))
                }
            }
            dispatch(openModal({ title: 'Incomplete Form', message: 'Looks like you missed a required field:', invalidFields: invalidFields }));

        } else if(totalImageSize >= 26214400) {
            dispatch(setFormLoading({loading: false}));
            dispatch(openModal({title: 'Too Many Images', message: 'You have exceeded the 25MB file limit. Please remove some of the attached images to continue.', invalidFields: []}))
        } else {
            for (let field in fields) {
                if (fields[field].name !== 'images' && fields[field].name !== 'tasks')
                    formData[fields[field].name] = fields[field].value
            }

            formData.tasks = tasks.checkboxes.filter(checkbox => checkbox.checked).map((checkbox) => checkbox.label);
            formData.images = images;

           
            const addDocument = async()=>{
                try{
                    const submissionId = uuid();
                    const uploadImageToFirebase = (image)=>{
                        return new Promise ((resolve, reject)=>{
                        const storageRef = ref(storage, `emailImages/${submissionId}/${image.name}`);
                        try{
                            const response = uploadString(storageRef, image.data, 'data_url');
                            resolve(response);
                        }
                        catch(error){
                            alert(error)
                        }
                        })
                    }
    
                    const processFirebaseImageUploads = ()=>{
                        const imageRefPromises = [];
                        formData.images.forEach((image)=>{
                            imageRefPromises.push(uploadImageToFirebase(image))
                          })
                          return Promise.all(imageRefPromises);
                    }
    
                    await processFirebaseImageUploads();
                    
                    await setDoc(doc(db, "emails", submissionId), {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        phone: formData.phone,
                        email: formData.email,
                        street: formData.street,
                        zip: formData.zip,
                        city: formData.city,
                        state: formData.state,
                        description: formData.description,
                        budget: formData.budget,
                        tasks: formData.tasks,
                        timestamp: new Date().toLocaleDateString()
                      });

                    dispatch(setFormLoading({ loading: false }));
                    dispatch(resetForm())
                    if(typeof window !== 'undefined'){
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        })
                    }
                    dispatch(openModal({ 
                        title: 'Success!', 
                        message: 'Your work request was submitted successfully. A confirmation email should have been sent to the email address provided', 
                        invalidFields: [] }));

                }
                catch(error){
                    dispatch(setFormLoading({loading: false}));
                    dispatch(openModal({ title: 'Error', message: error.message, invalidFields: [] }));
                }
            }
            addDocument();
        }
    }

    const fieldElements = fields.map((field) => {
        if (field.name !== 'images' && field.name !== 'tasks') {
            return <FormField
                type={field.type}
                label={field.label}
                name={field.name}
                isValid={field.isValid}
                touched={field.touched}
                key={field.name}
                required={field.required}
                multiple={field.multiple}
                value={field.value}
                onChange={handleUpdateTextField}
                onBlur={handleMarkFieldAsTouched}
            />
        } else {
            return null;
        }
    })

    return (
        <Layout location='work-request'>
            <Seo title={'Request a Project'} description={'Submit a work request to get started with your next home renovation project.'}/>
            <main>
                <div>
                    <div className={styles.formContainer}>
                        <Card>
                            <form className={styles.requestForm} onSubmit={submitForm}>
                                <h1>Request a project from Mike</h1>
                                <div className={styles.fields}>
                                    <h2 className={styles.sectionTitle} style={{gridArea: "contactHeader"}}>Contact Info</h2>
                                    <h2 className={styles.sectionTitle} style={{gridArea : "addressHeader"}}> Address</h2>
                                    <h2 className={styles.sectionTitle} style={{gridArea : "detailsHeader"}}> Project Details</h2>
                                    {fieldElements}
                                </div >
                                
                                <Checkboxes />
                                <ImageSection images={images} imageFieldData={imageFieldData}/>
                                <div className={styles.actions}>
                                    <button>
                                        {formLoading ?
                                            <Loading spinnerType={'button'}></Loading> :
                                            'Submit'
                                        }
                                    </button>
                                </div>
                            </form>

                        </Card>
                    </div>
                </div>
                {modal.show ? <Modal /> : null}
                {modal.showWindowBox ? <WindowBox/> : null}
            </main>
       
        </Layout>

    )
}

export default WorkRequest;