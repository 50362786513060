import * as React from 'react';
import * as styles from './WindowBox.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { closeWindowBox } from '../../../store/modal';

import { useEffect, useState, useCallback, useRef } from 'react';

const WindowBox = ()=>{
    const dispatch= useDispatch();
    const windowBoxData = useSelector(state=> state.modal.windowBoxData);
    const exitRef = useRef();

    const [windowBoxStyles, setWindowBoxStyles] = useState([styles.WindowBoxWrapper]);

    const handleCloseWindowBox = useCallback((event)=>{
        if(event.type === 'click' || event.keyCode === 27 || event.keyCode === 13){
        setWindowBoxStyles([styles.WindowBoxWrapper])
        setTimeout(()=>{
            dispatch(closeWindowBox());
        }, 100)
    }
    }, [dispatch])

    useEffect(()=>{
        if(typeof window !== undefined){
        window.addEventListener('keydown', handleCloseWindowBox)
        }
        exitRef.current.focus();
        setWindowBoxStyles((prevWindowBoxStyles)=>{
            return [...prevWindowBoxStyles, styles.show]
        })
        return ()=>{window.removeEventListener('keydown', handleCloseWindowBox)}
    }, [handleCloseWindowBox])

    return (
        <div className={windowBoxStyles.join(' ')}>
            <div className={styles.container}>
            <div className={styles.mobileHeader}>
                <p className={[styles.details, styles.showMobileDetails].join(" ")}>{windowBoxData.name} ({windowBoxData.size})</p>
                <button tabIndex="0" ref={exitRef} onKeyPress={handleCloseWindowBox} className={[styles.exit, styles.showMobileExit].join(" ")} onClick={handleCloseWindowBox}>
                    <div></div>
                    <div></div>
                </button>
            </div>
                <img src={windowBoxData.src} alt={windowBoxData.name} />
                    <p className={[styles.details, styles.showDesktopDetails].join(" ")}>{windowBoxData.name} ({windowBoxData.size})</p>
            </div>
            <button tabIndex="0" aria-label="CloseWindowBox" ref={exitRef} onKeyPress={handleCloseWindowBox} className={[styles.exit, styles.showDesktopExit].join(" ")} onClick={handleCloseWindowBox}>
                <div></div>
                <div></div>
            </button>
            <div role="button" tabIndex="0" aria-label="Close Window Box" className={styles.background} onKeyPress={handleCloseWindowBox} onClick={handleCloseWindowBox}></div>
        </div>
    )
}

export default WindowBox;