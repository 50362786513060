import * as styles from './FormField.module.css';
import * as React from 'react';
import {useRef, memo} from 'react';

const FormField = (props)=>{
    

    let field;
    const fileInputRef = useRef();

    const clickFileInput = ()=>{
        fileInputRef.current.click();
    }

    switch(props.type){
    case 'textarea':
        field = <div className={[styles.formGroup, styles.description, !props.isValid && props.touched ? styles.invalid : ''].join(' ')} key={props.name} style={{gridArea: `${props.name}`}}>
 <label htmlFor={props.name}>{props.label}{props.required? '*' : ''}</label>
                    <textarea 
                    name={props.name} 
                    id={props.name} 
                    placeholder='Tell me a bit about your project. The scope of the work will be detailed elsewhere; here I would like to know what are your goals for the project? For instance, you may want a tile shower. Perhaps this is to create a more spa-like environment at home, but the same work could be requested to increase accessibility and age in place. Do you have any fears or hesitation about working with a contractor? What do you expect when working with a construction professional?' 
                    className={styles.description}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}/>
            </div>
    break;
    case 'file':
        field = <button type="button" aria-label="Add Images" onClick={clickFileInput} className={[styles.fileInput].join(' ')} key={props.name}>

        <input 
        type={props.type} 
        multiple= {true}
        name={props.name} id={props.name} 
        value={props.value} 
        onChange={props.onChange}
         onBlur={props.onBlur}
         ref={fileInputRef}
          />
    </button>
    break;
    case 'number' :
        field = <div className={[styles.formGroup, !props.isValid && props.touched ? styles.invalid : ''].join(' ')} key={props.name} style={{gridArea: `${props.name}`}}>
        <label htmlFor={props.name}>{props.label}<span><i> (optional)</i></span></label>
        {props.name === 'budget' ? <p className={styles.dollar}>$</p> : null}
        <input 
        className={props.name === 'budget' ?styles.budget : ''}
        type={props.type} 
        name={props.name} 
        id={props.name} 
        value={props.value} 
        onChange={props.onChange}
        onBlur={props.onBlur} />
    </div>
    break;
    default:
        field = <div className={[styles.formGroup, !props.isValid && props.touched ? styles.invalid : ''].join(' ')} key={props.name} style={{gridArea: `${props.name}`}}>
        <label htmlFor={props.name}>{props.label}{props.required ? '*' : <span><i> (optional)</i></span>}</label>
        <input 
        type={props.type} 
        name={props.name} 
        id={props.name} 
        value={props.value} 
        onChange={props.onChange}
         onBlur={props.onBlur} />
    </div>
    }


    return field;
}

export default memo(FormField);