import * as styles from './Card.module.css';
import * as React from 'react';

const Card = (props)=>{

    const cardStyles = [styles.Card];
    let style = {};

    if(props.style){
        style = props.style;
    }

    if(props.unroundTop){
        cardStyles.push(styles.unroundTop);
    }

    if(props.unroundBottom){
        cardStyles.push(styles.unroundBottom);
    }

    return(
        <div className={cardStyles.join(' ')} style={style}>
            {props.children}
        </div>
    )
}

export default Card;