import * as styles from './ImageSection.module.css';
import * as React from 'react';
import { Fragment, memo } from 'react';

import Loading from '../../ui/Loading/Loading';
import FormField from '../FormField/FormField';
import ImageContainer from './ImageContainer/ImageContainer';

import addButton from '../../../images/add-button-white2.png';

import { useSelector, useDispatch } from 'react-redux';
import { processImageUploads } from '../../../store/form';


const ImageSection = (props) => {
    const dispatch = useDispatch()
    const imageFieldData = props.imageFieldData;

    const imagesLoading = useSelector(state => state.form.imagesLoading);
    const totalImageSize = useSelector(state => state.form.totalImageSize);
    const invalidSize = totalImageSize >= 26214400;

    const handleImageUploads = (event) => {
        if (event.target.files) {
            dispatch(processImageUploads({ formImages: event.target.files }));
        }
    }

    

    const imageSectionStyles = [styles.imagesSection];
    if(invalidSize){
        imageSectionStyles.push(styles.invalidSize, styles.invalidImages);
    }

    const imageField = <FormField
        type={imageFieldData.type}
        label={imageFieldData.label}
        name={imageFieldData.name}
        isValid={imageFieldData.isValid}
        touched={imageFieldData.isTouched}
        key={imageFieldData.name}
        multiple={imageFieldData.multiple}
        value={imageFieldData.text}
        onChange={handleImageUploads}
    />
    


    const imageElements = props.images.map((image) => {
        return (
            <ImageContainer 
            key={image.imageId} 
            imageId={image.imageId}
            data={image.data}
            name={image.name}
            UISize={image.UISize}
            batchNumber={image.batchNumber}
            />
        )
    });

    let UITotalSize = '';
    if(totalImageSize < 1024) {
        UITotalSize = `${totalImageSize} bytes`
    } else if (totalImageSize < 1048576){
        UITotalSize = `${(totalImageSize /1024).toFixed(0)} KB`
    } else if (totalImageSize < 1073741824){
        UITotalSize = `${(totalImageSize / 1048576 ).toFixed(2)} MB`
    } else {
        UITotalSize = `${(totalImageSize / 1073741824 ).toFixed(2)} GB`
    }
    

    return (
        <div className={imageSectionStyles.join(" ")}>
            <label htmlFor='images'>Add a few pictures to show the space you want to be renovated/repaired.</label>
            <ul className={styles.imageBox}>
                <p className={styles.totalSize}>File Limit: {UITotalSize} / 25MB</p>
                {imageElements}
                <div className={styles.addImageContainer}>
                    {imagesLoading
                        ? <Loading spinnerType={'addImage'} />
                        : <Fragment>
                            {imageField}
                            <h2>Add</h2>
                            <div className={styles.addImage}>
                                <img src={addButton} alt="add_images" />
                            </div>
                        </Fragment>
                    }
                </div>
            </ul>
        </div>
    )
}

export default memo(ImageSection);