import * as styles from './Loading.module.css';
import * as React from 'react';

const Loading = (props)=>{

    let spinnerClasses = [styles.spinner];
    switch(props.spinnerType){
        case 'button':
        spinnerClasses.push(styles.button)
        break;
        case 'addImage':
        spinnerClasses.push(styles.addImage)
        break;
        default:
            spinnerClasses.push(styles.spinner)
        break;
    }

return <div className={spinnerClasses.join(' ')}></div>
}

export default Loading;